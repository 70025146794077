<template>
  <modal
    title="Update Event Email Template"
    width="800px"
    :active="active"
    @close="close"
  >
    <h4 class="name">{{ email.name }}</h4>
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="checkbox"
          label="Use Custom Template"
          v-model="formValues.useCustomTemplate"
          :checked="formValues.useCustomTemplate"
        />
      </div>
      <!--      <div class="col half">-->
      <!--        <FormulateInput-->
      <!--          type="checkbox"-->
      <!--          label="Delay Sending Email"-->
      <!--          v-model="formValues.delayed"-->
      <!--          :checked="formValues.delayed != '0'"-->
      <!--        />-->
      <!--      </div>-->
      <!-- <div class="col half">
        <diyobo-switch :label="'Enabled'" :value="formValues.enabled" />
      </div> -->
    </div>

    <template v-if="formValues.delayed">
      <h4>Delay Options</h4>
      <div class="row">
        <div class="col half">
          <FormulateInput
            type="number"
            label="Delay (in minutes)"
            placeholder="Delay"
            validation="required"
            v-model="formValues.delay_minutes"
          />
        </div>
      </div>
    </template>
    <template v-if="formValues.useCustomTemplate">
      <h4>Custom Template Content</h4>
      <template v-if="languages.length == 0">
        <FormulateInput
          type="text"
          label="Subject"
          placeholder="Subject"
          v-model="formValues.subject"
          :val="formValues.subject"
          validation="required"
        />
        <div id="wysiwyg-wrapper">
          <FormulateInput
            type="wysiwyg"
            label="Email Content"
            placeholder="Write your message here..."
            validation="required"
            :hasVars="true"
            :variables="email.variables"
            v-model="formValues.content"
            :val="formValues.content"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="translate-box"
          v-for="(translate, index) in translates"
          :key="index"
          :id="`translate-${index}`"
        >
          <FormulateInput
            type="selectSearch"
            label="Language"
            placeholder="Select Language"
            validation="required"
            :options="filterNonselectedTranslateLanguages"
            v-model="translate.language"
            :disabled="translate.language === 'English'"
          />
          <FormulateInput
            type="text"
            label="Subject"
            placeholder="Subject"
            validation="required"
            v-model="translate.subject"
          />
          <div id="wysiwyg-wrapper">
            <FormulateInput
              type="wysiwyg"
              label="Email Content"
              placeholder="Write your message here..."
              validation="required"
              :hasVars="true"
              :variables="email.variables"
              v-model="translate.content"
            />
          </div>
          <FormulateInput
            type="button"
            name="Delete"
            v-if="index > 0"
            @click="removeTranslate(index)"
          />
        </div>
        <FormulateInput
          type="button"
          name="Add Another Language"
          @click="addTranslate"
        />
      </template>
    </template>

    <div v-else class="default-template">
      <h4>Default Template Content</h4>
      <div class="row">
        <!-- <div class="col half field">
          <span>Name</span>
          {{ email.default_template.name }}
        </div>
        <div class="col half field">
          <span>Email</span>
          {{ email.default_template.email }}
        </div> -->
      </div>
      <div class="row field">
        <span>Subject</span>
        <div v-html="email.subject"></div>
      </div>
      <div class="row field">
        <span>Content</span>
        <div v-html="email.content"></div>
      </div>
    </div>

    <template #footer>
      <diyobo-button type="secondary" txt="Close" @click="close" />
      <diyobo-button type="primary" txt="Save" @click="submit" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import DiyoboSwitch from "@/components/DiyoboSwitch.vue";
import Vue from "vue";

export default {
  name: "update-email-template-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton,
    DiyoboCheckbox,
    DiyoboSwitch
  },
  props: {
    event: String,
    email: Object,
    languages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false,
      // email: {  },
      index: -1,
      bus: new Vue(),
      previousEmail: null,
      formValues: {
        useCustomTemplate: false,
        enabled: false,
        templateName: "",
        name: "",
        email: "",
        subject: "",
        content: ""
      },
      formErrors: {
        useCustomTemplate: false,
        templateName: "",
        name: "",
        email: "",
        subject: "",
        content: ""
      },
      translates: [
        {
          language: "English",
          subject: "",
          content: ""
        }
      ]
    };
  },
  computed: {
    filterNonselectedTranslateLanguages() {
      return this.languages.filter(
        l => !this.translates.find(t => t.language === l)
      );
      // this.languages.forEach(l => {
      //   this.translates.forEach(t => {
      //     if (t.language !== l) {

      //     }
      //   })
      // });
    }
  },
  methods: {
    addTranslate() {
      if (this.filterNonselectedTranslateLanguages.length === 0) {
        return;
      }
      this.translates.push({
        language: "",
        subject: "",
        content: ""
      });

      const index = this.translates.length - 1;

      setTimeout(() => {
        document.getElementById(`translate-${index}`).scrollIntoView({
          behavior: "smooth"
        });
      }, 100);
    },
    removeTranslate(index) {
      setTimeout(() => {
        this.translates.splice(index, 1);
      }, 100);
    },

    open(email) {
      console.log(email);
      document.body.style.overflow = "hidden";
      if (email.config) {
        this.formValues.enabled = email.config.enabled;
        this.formValues.templateName = email.config.templateName;
        this.formValues.useCustomTemplate = email.config.use_custom_template;
        this.formValues.delayed = email.config.delay_minutes || 0;
        this.formValues.subject = email.config.subject;
        this.formValues.delayed = email.config.content || 0;
        this.formValues.configId = email.config._id;
        if (email.config.translations) {
          this.translates = email.config.translations;
        }
      }
      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;

      setTimeout(() => {
        document.body.style.position = "relative";

        // this.email = { default_template: {} };
        // this.index = -1;
      }, 500);
    },

    async submit() {
      const enabled = this.email.use_custom_template
        ? this.formValues.enabled
        : true;

      const config = {
        enabled,
        ...this.formValues,
        templateName: this.email.type,
        templateId: this.email._id
      };
      if (this.languages.length > 0) {
        config.translates = this.translates;
      }

      this.$emit("submit", config);
      this.close();
    }
  }
};
</script>

<style lang="less" scoped>
.modal &::v-deep {
  #insertHandlebars-1 {
    color: white;
  }
  .name {
    margin: 0 0 20px 0;
    text-align: center;
  }

  .default-template {
    .row {
      margin-bottom: 8px !important;
    }

    .field {
      display: flex;
      flex-direction: column;

      span {
        font-weight: bold;
      }
    }
  }

  .template-name {
    display: flex;

    button {
      margin: 32px 0 auto 16px;
    }
  }

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}

.translate-box {
  border: 1px solid #3f444b;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  background: var(--card-background);
  margin: 10px 0 20px 0;
}
</style>
