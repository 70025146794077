<template>
  <div class="custom-email">
    <h2>Custom Event Emails</h2>
    <div class="row">
      <div class="col third" v-for="(email, index) in emails" :key="index">
        <custom-email-tile
          :eventId="eventId"
          :key="email.type"
          :email="email"
          :languages="languages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

import CustomEmailTile from "../../components/emails/CustomEmailTile.vue";
export default {
  name: "event-emails",
  components: {
    Loader,

    CustomEmailTile
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Custom Event Emails"
    };
  },
  data() {
    return {
      emails: [],
      eventId: "",
      email: {
        type: "PURCHASE_CONFIRMATION",
        use_custom_template: false,
        delayed_minutes: 0,
        enabled: true,
        name: "Purchase Confirmation",
        subject: "Your event has been purchased!",
        content: `<div style="font-family: inherit; text-align: center"><span style="font-size: 24px; color: #29abe2; font-family: helvetica, sans-serif"><strong>Thank you for your purchase!</strong></span></div>
<div style="font-family: inherit; text-align: center"><br></div>
<div style="font-family: inherit; text-align: inherit">Hi {{firstName}},</div>
<div style="font-family: inherit; text-align: inherit"><br></div>
<div style="font-family: inherit; text-align: inherit">Thank you for your purchase on INCREDEVENT<span style="color: #000000; font-family: arial, helvetica, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: preserve; text-wrap: wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline"> for {{{eventName}}}</span>! Your purchase and receipt can be found in your account by logging in and clicking on your profile, then clicking "My Purchases".</div>
<div style="font-family: inherit; text-align: inherit"><br></div>
<div style="font-family: inherit; text-align: inherit">For virtual access purchasers, your dashboard link will be sent approximately 24 hours before the event to your portal and email.</div>`
      },
      languages: [
        "English",
        "French",
        "Spanish",
        "Portuguese",
        "German",
        "Italian",
        "Russian",
        "Chinese",
        "Japanese",
        "Hindi"
      ]
    };
  },
  // methods: {
  //   onUpdateTemplate(automation, index) {
  //     this.$refs.updateAutomationTemplateModal.open({ automation, index });
  //   },
  //   onAutomationChange(config, index) {
  //     const automation = this.automations[index];

  //     // if this is coming from the template editor, then we want to instantly update the template type
  //     if (config.useCustomTemplate !== undefined) {
  //       automation.config.use_custom_template = config.useCustomTemplate;
  //     }

  //     this.$axios
  //       .post(
  //         `/emails/automations/${this.url}/${automation.type}/configure`,
  //         config
  //       )
  //       .then(response => {
  //         const data = response.data;

  //         this.$set(automation, "config", data.config);
  //       });
  //   }
  // },
  created() {
    this.$axios.get("/emails/email-types/" + this.url).then(response => {
      const data = response.data;

      this.$emit("set-title", data.event.name);

      data.emails.forEach(email => {
        email.variables = email.fields.reduce((acc, field) => {
          acc[field.name] = `${field.label} - ${field.description}`;
          return acc;
        }, {});

        // email.fields.forEach(field => {
        //   email.variables.push({
        //     [field.name]: `${field.label} - ${field.description}`
        //   });
        // });
      });
      this.emails = data.emails;
      this.languages = data.event.languages;
      this.eventId = data.event._id;
    });
  }
};
</script>

<style lang="less" scoped>
.custom-email-tile {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #3f444b;
}

.title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;

  .status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--primary-green);
  }
}
.template-selection {
  text-align: center;
  border: 1px solid var(--border);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  background: var(--card-background);
  cursor: pointer;
  font-size: 14px;
  .template {
    color: var(--primary-green);
    font-weight: bold;
  }

  .update-template {
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
