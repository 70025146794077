<template>
  <div class="custom-email-tile">
    <update-event-email-modal
      ref="updateEventEmailModal"
      :event="url"
      :email="email"
      :languages="languages"
      @submit="onUpdateEmail"
    />
    <div class="title">
      <span class="status" :class="{ active: true }"></span>
      {{ email.name }}
    </div>
    <div class="description">
      <p>
        {{ email.description }}
      </p>
    </div>
    <!-- <div class="col full">
            <FormulateInput
              type="button"
              name="update"
              @click="onUpdateTemplate('purchaseSuccessEmail', 0)"
            >
              Update
            </FormulateInput>
          </div> -->

    <div
      class="template-selection"
      @click="$refs.updateEventEmailModal.open(email)"
    >
      <span>
        Selected Template:
        <span class="template">
          {{ email.use_custom_template ? "Custom" : "Default" }}
        </span>
      </span>

      <div class="update-template">
        Click to Update
      </div>
    </div>
  </div>
</template>

<script>
import UpdateEventEmailModal from "../../components/modals/UpdateEventEmailModal.vue";

export default {
  components: {
    UpdateEventEmailModal
  },
  name: "CustomEmailTile",
  props: {
    email: Object,
    languages: Array,
    eventId: String,
  },
  data() {
    return {};
  },
  computed: {
    url() {
      return this.$route.params.url;
    }
  },
  methods: {
    onUpdateEmail(email) {
      // Logic to update email here
      console.log(email);
      if (email.configId) {
        this.$axios.post('custom-emails/update', {eventId: this.eventId, ...email}).then((response) => {
          this.$toast(response.data.message);
        });
      } else {
        this.$axios.post('custom-emails/create', {eventId: this.eventId, ...email}).then((response) => {
          this.$toast(response.data.message);
        });
      }
    }
    // onUpdateTemplate(automation, index) {
    //   this.$refs.updateAutomationTemplateModal.open({ automation, index });
    // }
  }
};
</script>

<style lang="less" scoped>
.custom-email-tile {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #3f444b;
}

.title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;

  .status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--primary-green);
  }
}

.template-selection {
  text-align: center;
  border: 1px solid var(--border);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  background: var(--card-background);
  cursor: pointer;
  font-size: 14px;

  .template {
    color: var(--primary-green);
    font-weight: bold;
  }

  .update-template {
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
